import React, { createRef, useEffect, useMemo, useState } from 'react';
// @ts-ignore
import { Header, HeaderProps } from '../../components/header';
// @ts-ignore
import Footer from '../../components/footer';
import Sidebar from '../../components/sidebar';
import TableOfContents from '../../components/sidebar/table-of-contents';
import RightNav from '../../components/sidebar/right-nav';
import Community from '../../components/sidebar/community';
// @ts-ignore
import DeploymentSelector from '../../components/deployment-selector';
// @ts-ignore
import AdditionalHelp from '../../components/additional-help';
// @ts-ignore
import FeedbackButtons from '../../components/feedback-buttons';
// @ts-ignore
import Breadcrumbs from '../../components/breadcrumbs';
import {
    PageTree,
    PageTreeList,
    PageTreeItem
} from '../../components/page-tree';
import './topic.less';
// @ts-ignore
import AuthorTools from '../../components/author-tools';
// @ts-ignore
import { TOPIC } from '../../../contentful/content-types';
// @ts-ignore
import { CardGroup, CardWithCEM } from '../../components/card';
import { CollectionTreeType } from '../../components/sidebar/sidebar.types';
import { getUpNextContent } from './util/get-up-next-content';
import {
    TopicSac,
    isProductContext,
    isThemeContext
} from '../../../content/types/content';
import {
    getContextName,
    getSupportTitle
} from '../../../content/context-utils';
import NoticeHub from '../../components/notice-hub';
import Video from '../../components/video';
import { useContentfulLiveUpdates } from '@contentful/live-preview/react';
// @ts-ignore
import AllIcons from '../../../adf-extensions/icon/utils/all-icons';
import { getExtensionHandlers } from '../../components/extensions/extension-handlers';

export type TopicPageEntry = TopicSac & {
    header: HeaderProps;
    preview: boolean;
    resources: any;
};

interface SACTopicPageProps {
    entry: TopicPageEntry;
    pageTree: CollectionTreeType[];
    featureFlags: {
        isVideoEnabled: boolean;
    };
}

/* eslint-disable complexity */
const SACTopicPage = ({ entry, pageTree, featureFlags }: SACTopicPageProps) => {
    const updatedEntry = useContentfulLiveUpdates(entry, {
        skip: !entry.preview
    });

    const {
        topicTitle,
        body,
        header,
        id,
        preview,
        tableOfContents,
        versions,
        context,
        collectionId,
        topicSetId,
        url,
        resources,
        pageType,
        confluencePageId,
        collections,
        relatedAppReferences,
        featuredVideo,
        extensionsData,
        type
    } = updatedEntry;

    const { isVideoEnabled } = featureFlags;

    const subContentRef = createRef<HTMLDivElement>();

    const isProgressBarEnabled = !!topicSetId && pageType === 'Guide';

    const collectionTree = pageTree.find(
        (collection) => collection.id === collectionId
    );

    const { prevGuideCard, nextGuideCard, otherGuideTopicSets } =
        useMemo(() => {
            return getUpNextContent(
                {
                    topicTitle,
                    body,
                    id,
                    collectionId,
                    topicSetId
                },
                isProgressBarEnabled,
                pageTree
            );
        }, [
            isProgressBarEnabled,
            topicSetId,
            id,
            body,
            collectionId,
            pageTree,
            topicTitle
        ]);

    const productName = getContextName(context);

    const [updatedBody, setUpdatedBody] = useState<JSX.Element>();
    const [allIcons, setAllIcons] = useState<any>(null);

    // Render the Topic body using @atlaskit/renderer on the client side for Contentful live preview
    useEffect(() => {
        if (!preview) {
            return;
        }
        (async () => {
            // @ts-ignore
            const { ReactRenderer } = await import('@atlaskit/renderer');
            // @ts-ignore
            const { IntlProvider } = await import('react-intl-next');

            if (!allIcons) {
                setAllIcons(await AllIcons());
            }

            const extensionHandlers = getExtensionHandlers({
                ...extensionsData,
                icon: {
                    hideToolTips: true,
                    allIcons
                }
            });
            const adfBody =
                // @ts-ignore
                type === TOPIC ? body : updatedEntry?.derivedFrom?.body;
            const bodyRenderedAdf = (
                <IntlProvider locale="en">
                    <ReactRenderer
                        document={adfBody}
                        extensionHandlers={extensionHandlers}
                    />
                </IntlProvider>
            );
            setUpdatedBody(bodyRenderedAdf);
        })();
    }, [allIcons, body, extensionsData, preview, type, updatedEntry]);

    return (
        <>
            {
                <AuthorTools
                    pageId={id}
                    tooltipContent="Edit this topic "
                    confluencePageId={confluencePageId}
                />
            }
            <div
                className="topic section-page flex-column-page"
                data-testid="page-template"
            >
                <div className="content">
                    <Header
                        title={getSupportTitle(context)}
                        id={header.id}
                        pageId={id}
                        cloudProducts={header.cloudProducts}
                        serverProducts={header.serverProducts}
                        additionalLinks={header.additionalLinks}
                        showProductDropdownAsWaffle={true}
                        showPreviewBanner={preview}
                        resources={resources}
                        supportHomePath={
                            isProductContext(context)
                                ? context.deploymentUrls[
                                      context.deployment.toLowerCase()
                                  ] || context.deploymentUrls.default
                                : '/'
                        }
                        collections={collections}
                        logo={header.logo}
                        atlassianSupportLogo={header.atlassianSupportLogo}
                        atlassianLogo={header.atlassianLogo}
                        shouldRenderDocumentation
                        relatedAppReferences={relatedAppReferences}
                        supportFormUrls={{
                            authenticated: isProductContext(context)
                                ? context.customSupportFormAuthenticated
                                : null,
                            unauthenticated: isProductContext(context)
                                ? context.customSupportFormUnauthenticated
                                : null
                        }}
                    />
                    <div className="main-content-container flex-container margin-top-medium">
                        <PageTree
                            context={context}
                            subContentRef={subContentRef}
                        >
                            <PageTreeList isRoot>
                                {pageTree.map(
                                    ({ title, slug, id, childList }) => (
                                        <PageTreeItem
                                            key={id}
                                            title={title}
                                            slug={slug}
                                            id={id}
                                            childList={childList}
                                        />
                                    )
                                )}
                            </PageTreeList>
                        </PageTree>
                        <main className="topic__container">
                            <div
                                className="topic__sub-header"
                                data-testid="topic_sub_header"
                            >
                                <Breadcrumbs
                                    pageTree={pageTree}
                                    entryId={id}
                                    context={context}
                                    type={TOPIC}
                                />
                                {!isThemeContext(context) && (
                                    <DeploymentSelector
                                        selected={context.deployment}
                                        deployments={context.deploymentUrls}
                                        versions={versions}
                                    />
                                )}
                            </div>

                            {context.notice && (
                                <NoticeHub notice={context.notice} />
                            )}

                            <article
                                className="topic__inner-container margin-top-medium"
                                ref={subContentRef}
                                id="maincontent"
                            >
                                <div className="title margin-bottom-medium">
                                    <h1>{topicTitle}</h1>
                                </div>

                                <div className="topic__body">
                                    {isVideoEnabled &&
                                        featuredVideo?.videoUrl && (
                                            <Video
                                                featuredVideo={featuredVideo}
                                            />
                                        )}
                                    {preview ? (
                                        updatedBody
                                    ) : (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: body
                                            }}
                                        />
                                    )}
                                    <FeedbackButtons
                                        productName={
                                            isProductContext(context)
                                                ? context.internalTitle
                                                : getContextName(context)
                                        }
                                        pageId={id}
                                    />
                                    <AdditionalHelp
                                        productNames={[productName]}
                                        deployments={[context.deployment]}
                                    />
                                </div>
                                <Sidebar>
                                    {collectionTree && (
                                        <RightNav
                                            currentPageId={id}
                                            collectionTree={collectionTree}
                                            topicSetRef={topicSetId}
                                            isProgressBarEnabled={
                                                isProgressBarEnabled
                                            }
                                        />
                                    )}
                                    {url && (
                                        <TableOfContents
                                            links={tableOfContents}
                                            url={url}
                                        />
                                    )}
                                    <Community
                                        contextType={context.type}
                                        productName={productName}
                                    />
                                </Sidebar>
                            </article>
                            {isProgressBarEnabled && (
                                <div className="up-next-container">
                                    <CardGroup
                                        guide={isProgressBarEnabled}
                                        previous={prevGuideCard}
                                        next={nextGuideCard}
                                        otherGuideTopicSets={
                                            otherGuideTopicSets
                                        }
                                    >
                                        {nextGuideCard && (
                                            <CardWithCEM
                                                {...nextGuideCard}
                                                type="full"
                                            />
                                        )}
                                    </CardGroup>
                                </div>
                            )}
                        </main>
                    </div>
                </div>
                <Footer
                    logo={header.atlassianLogo.url}
                    className={'margin-top-large'}
                />
            </div>
        </>
    );
};

export default SACTopicPage;
